<template>
  <b-container>
    <b-row class="mb-6">
      <b-col>
        <h1>Bienvenido {{ currentUser.attributes.name }}</h1>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols md="12">
        <b-card no-body>
          <b-tabs card>
            <b-tab
              v-if="this.currentUser.attributes.rol_id === 4"
              title="Vacaciones"
              :active="
                this.currentUser.attributes.rol_id == 4 || example == 'setting'
                  ? true
                  : false
              "
            >
              <div><SETTINGVACATION /></div>
            </b-tab>
          </b-tabs>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapGetters } from "vuex";
import SETTINGVACATION from "@/components/holidays-module/setting/setting-vacation";
export default {
  name: "admin-dashboard",
  components: {
    SETTINGVACATION
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["currentUser"])
  }
};
</script>

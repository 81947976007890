<template>
  <div>
    <h4>Permisos (Socios/Lideres)</h4>
    <p>Para poder asignar días de vacaciones</p>
    <b-card
      class="card card-custom card-stretch gutter-b"
      v-if="this.currentUser.attributes.rol_id === 4"
    >
      <b-row>
        <b-col sm="12" md="6" class="my-1">
          <b-form-group
            label="Filtrar"
            label-for="filter-input"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filter-input"
                v-model="filter"
                type="search"
                placeholder="Escribe para buscar"
              ></b-form-input>

              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''"
                  >Borrar</b-button
                >
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col sm="12" md="6" class="my-1">
          <b-form-group
            label="Por pagina"
            label-for="per-page-select"
            label-cols-sm="6"
            label-cols-md="4"
            label-cols-lg="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-0"
          >
            <b-form-select
              id="per-page-select"
              v-model="perPage"
              :options="pageOptions"
              size="sm"
            ></b-form-select>
          </b-form-group>
        </b-col>

        <b-col sm="12" md="12" class="my-1">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="fill"
            size="sm"
            class="my-0"
          ></b-pagination>
        </b-col>
      </b-row>

      <!-- Main table element -->
      <b-table
        striped
        hover
        :items="items"
        :fields="fields"
        :current-page="currentPage"
        :per-page="perPage"
        :filter="filter"
        :filter-included-fields="filterOn"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        stacked="md"
        show-empty
        small
        @filtered="onFiltered"
      >
        <template #cell(permission_add)="row">
          <b-badge :variant="row.value ? 'success' : 'danger'">{{
            row.value ? "Tiene permisos" : "Sin permisos"
          }}</b-badge>
        </template>

        <template #cell(actions)="row">
          <b-badge
            href="#"
            @click="change_permission(row.item)"
            :variant="row.item.permission_add ? 'danger' : 'success'"
          >
            <b-icon
              aria-hidden="true"
              :icon="row.item.permission_add ? 'x-circle' : 'check-circle'"
              :title="
                row.item.permission_add
                  ? 'Activar permiso para vacacion'
                  : 'Desactivar permiso para vacacion'
              "
            >
            </b-icon>
            {{ row.item.permission_add ? "Quitar Permiso" : "Dar Permiso" }}
          </b-badge>
        </template>
      </b-table>
    </b-card>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
var _ = require("lodash");
// ==========sweetalert2===============
const Swal = require("sweetalert2");
import {
  LIST_EMPLOYEES_SETTING,
  CHANGE_EMPLOYEE_PERMISSION
} from "@/services/store/holiday.module.js";

export default {
  comments: {
    Swal
  },
  data() {
    return {
      fields: [
        {
          key: "index",
          label: "#"
        },
        {
          key: "employee",
          sortable: true,
          label: "Nombre",
          sortDirection: "desc"
        },

        {
          key: "type",
          label: "Cargo",
          sortable: true
        },

        {
          key: "permission_add",
          label: "Estado",
          sortable: true
        },
        {
          key: "actions",
          label: "Acciones"
        }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, { value: 100, text: "Ver todo" }],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: []
    };
  },
  computed: {
    ...mapGetters(["getEmployeesSetting", "currentUser"]),
    sortOptions() {
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key };
        });
    },
    items() {
      let items = [];
      let employees = [];
      employees = _.orderBy(this.getEmployeesSetting, ["id"], ["desc"]);
      for (var idx in employees) {
        items.push({
          index: parseInt(idx) + 1,
          id: employees[idx].id,
          employee: employees[idx].attributes.name,
          type: employees[idx].attributes.type,
          permission_add: employees[idx].attributes.permission_add
        });
      }
      return items;
    }
  },
  beforeMount() {
    this.$store.dispatch(LIST_EMPLOYEES_SETTING);
  },
  mounted() {
    this.totalRows = this.items.length;
  },
  beforeUpdate() {
    this.totalRows = this.items.length;
  },
  methods: {
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    change_permission(employee) {
      if (employee.permission_add) {
        this.change_permission_employee(employee, "quitar");
      } else {
        this.change_permission_employee(employee, "dar");
      }
    },
    change_permission_employee(employee, msg) {
      Swal.fire({
        title:
          "¿Estas seguro de " +
          msg +
          " permiso de configuracion en el modulo de vacaciones?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Si," + msg + " permiso"
      }).then(result => {
        if (result.isConfirmed) {
          this.$store
            .dispatch(CHANGE_EMPLOYEE_PERMISSION, employee)
            .then(() => {
              Swal.fire(
                "Exito!",
                "Al usuario  se le cambio el permiso.",
                "success"
              );
              this.$store.dispatch(LIST_EMPLOYEES_SETTING);
            })
            .catch(e => {
              console.error(e);
              Swal.fire(
                "Error!",
                "Al usuario no se le cambio el permiso. Intentelo nuevamente",
                "danger"
              );
            });
        }
      });
    }
  }
};
</script>
